
[data-v-0dd2f1ee] .el-card__body {
  padding: 0px !important;
}
.box-card[data-v-0dd2f1ee] {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: rgb(244, 245, 246);
}
.listBox[data-v-0dd2f1ee] {
  /* height: 70vh; */
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-color: white;
  /* margin-top: 20px; */
  /* border: 1px solid black; */
}
.box1[data-v-0dd2f1ee] {
  width: 30%;
  margin: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.textbt[data-v-0dd2f1ee] {
  font-size: 16px;
  color: blue;
  cursor: pointer;
}
